<template>
  <h2 class="page-title">Dashboard</h2>
  <p class="error" v-if="error">{{ error }}</p>
  <ul class="filter-group">
    <li v-on:click="updateFilter('user')" :class="{ active : filter === 'user' }">My Lists</li>
    <li v-on:click="updateFilter('group')" :class="{ active : filter === 'group' }">Group Lists</li>
  </ul>
  <div class="list-group">
    <div class="list-link-more" v-for="list in lists" v-bind:key="list.id" v-bind:class="{ active: activeRow === list }">
      <router-link :to="'/' + list.user.username + '/' + list.url">
        <div class="list-image">
          <img :src="apiUrl + list.user.image" :alt="list.user.username">
          <div v-if="list.members.length > 0" class="members">
            <template v-for="member in list.members.slice(0, 3)" v-bind:key="member.id">
              <img :src="apiUrl + member.image" :alt="member.username">
            </template>
          </div>
        </div>
        <div class="list-details">
          <h3>{{ list.title }} <span v-if="list.visibility === 0" class="icon-lock" title="Private"></span></h3>
          <div class="username">{{ list.user.username }}</div>
        </div>
        <div class="icon icon-more" v-on:click.prevent="openMore(list)" title="More"><span class="srt">More</span></div>
        <div class="list-actions">
          <router-link class="icon icon-open" :to="'/' + list.user.username + '/' + list.url" title="View"><span class="srt">View</span></router-link>
          <router-link v-if="filter === 'user'" class="icon icon-settings" :to="'/list/settings/' + list.id" title="Settings" tabindex="-1"><span class="srt">Settings</span></router-link>
          <span v-if="filter === 'user'" class="icon icon-delete" v-on:click.prevent="deleteList(list)" title="Delete"><span class="srt">Delete</span></span>
        </div>
      </router-link>
    </div>
  </div>
  <InfiniteLoader @handler="infiniteHandler" />
  <router-link class="btn" to="/list/add">New List</router-link>
</template>

<script>
import InfiniteLoader from '@/components/InfiniteLoader.vue'
import axios from 'axios'
import AuthService from '../services/auth.service'
import authHeader from '../services/auth-header'
import CacheService from '../services/cache.service'
import store from '../store.js'

const API_URL = process.env.VUE_APP_API_URL || 'https://api.socia-lists.com'

export default {
  name: 'Dashboard',
  components: {
    InfiniteLoader
  },
  data () {
    return {
      lists: [],
      page: 0,
      activeRow: null,
      error: '',
      filter: 'user',
      apiUrl: API_URL
    }
  },
  created () {
    this.filter = store.dashboardFilter
    CacheService.load('dashboard-cache-' + this.filter)
  },
  methods: {
    infiniteHandler: function (stateHandler) {
      if (stateHandler.isFirst && CacheService.isValid()) {
        const cache = CacheService.get()
        this.lists = cache.lists
        this.page = cache.current_page
        if (cache.current_page >= cache.last_page) {
          stateHandler.finished()
        } else {
          stateHandler.complete()
        }
      } else {
        this.getLists(stateHandler)
      }
    },
    getLists: function (stateHandler) {
      const url = (this.filter === 'group') ? '/me/lists/group' : '/me/lists'
      axios
        .get(API_URL + url + '?page=' + (this.page + 1), {
          headers: authHeader()
        })
        .then(response => {
          // console.log(response)
          this.lists.push(...response.data.data)
          CacheService.set({
            lists: this.lists,
            current_page: response.data.current_page,
            last_page: response.data.last_page
          })
          this.page = response.data.current_page
          if (response.data.current_page < response.data.last_page) {
            stateHandler.complete()
          } else {
            stateHandler.finished()
          }
        })
        .catch(error => {
          stateHandler.finished()
          this.error = error
          console.log(error)
          // this.logout()
        })
    },
    updateFilter: function (filter) {
      // Reset the current page data.
      this.lists = []
      this.page = 0
      this.activeRow = null

      // Update the filter type.
      this.filter = filter
      store.dashboardFilter = filter

      // Load the current filter cache.
      CacheService.load('dashboard-cache-' + this.filter)

      // Using a fake state handler so we can reuse the ininiteHandler function.
      const fakeStateHandler = {
        isFirst: true,
        loading: () => {},
        complete: () => {},
        finished: () => {}
      }
      this.infiniteHandler(fakeStateHandler)
    },
    openMore: function (list) {
      this.activeRow = list
    },
    deleteList: function (list) {
      if (confirm('Are you sure you want to delete this list?')) {
        this.lists.splice(this.lists.indexOf(list), 1)
        axios
          .delete(API_URL + '/lists/' + list.id, {
            headers: authHeader()
          })
          .then(response => {
            CacheService.removeByKey('dashboard-cache-user')
            CacheService.removeByKey('dashboard-cache-group')
            CacheService.removeByKey('search-cache')
          })
          .catch(error => {
            this.error = error
            console.log(error)
          })
      }
    },
    logout: function () {
      AuthService.logout()
      this.$router.push('/')
    }
  }
}
</script>

<style scoped lang="sass">
h3
  margin-bottom: 0
.filter-group
  background-color: #F9F3F9
  border-radius: 10px
  display: flex
  max-width: 600px
  margin: 0 auto 10px
  padding: 0
  li
    flex: 1
    text-align: center
    list-style: none
    padding: 10px
    cursor: pointer
    font-size: 15px
    &.active
      background-color: #000
      color: #fff
      border-radius: 10px
.list-link-more
  position: relative
  &:first-child,
  &:last-child
    .list-actions
      background-color: transparent
      box-shadow: none
  &:last-child
    .list-actions
      border-bottom: 0
  & > a
    display: flex
    align-items: center
.list-image
  width: 40px
  position: relative
  img
    border-radius: 50%
    vertical-align: middle
    position: relative
    z-index: 1
.members
  img
    max-width: 20px
    position: absolute
    right: -7px
    bottom: -5px
    z-index: 4
    border: 2px solid #fff
    box-shadow: 0 0 1px rgba(0,0,0,0.75)
    &:nth-child(3)
      right: auto
      left: -7px
      z-index: 3
    &:nth-child(2)
      right: 10px
      bottom: -10px
      z-index: 5
.list-details
  padding-left: 15px
  h3
    margin-bottom: 0
  .username
    color: #999
    font-size: 14px
.icon
  width: 50px
  height: 100%
  display: inline-block
  background-repeat: no-repeat
  background-position: center center
  cursor: pointer
.icon-lock
  width: 18px
  height: 18px
  display: inline-block
  vertical-align: middle
  background: url('../assets/icon-hidden.svg') center center no-repeat #ff2768
  background-size: 14px
  border-radius: 100%
.icon-open
  background-image: url('../assets/icon-open.svg')
.icon-settings
  background-image: url('../assets/icon-settings.svg')
.icon-delete
  background-image: url('../assets/icon-delete.svg')
.icon-more
  background-image: url('../assets/icon-more.svg')
  background-position: 17px center
  transition: transform 200ms ease-in-out
  transform: translateX(0)
  .active &
    transform: translateX(100%)
.icon-more,
.list-actions
  position: absolute
  top: 0
  right: 0
  height: 100%
  z-index: 5
.list-actions
  transform: translateX(100%)
  transition: transform 200ms ease-in-out
  border-bottom: 1px solid #F9F3F9
  background-color: #fff
  box-shadow: -15px 0 5px -7px #fff
  .active &
    transform: translateX(0)
a:focus .list-actions
  background-color: #000
  box-shadow: -15px 0 5px -7px #000
</style>
